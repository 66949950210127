import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Caption } from '@sdflc/ui';
import { DefaultLayout, PageLoading } from '../components';
import { SquareUpContext } from '../contexts';
import donut from '../images/donut_0.svg';
import four from '../images/four.svg';

const NotFoundPage = memo((props) => {
  const { firstTimeLoading } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return (
    <DefaultLayout title='Page Not Found'>
      <Flex height='100vh' width='100%' alignItems='center' justifyContent='center' flexDirection='column'>
        <Caption level={3} textAlign='center'>
          OOPS! PAGE NOT FOUND
        </Caption>
        <Flex mt={5} mb={5}>
          <img src={four} height='100px' />
          <img src={donut} height='100px' />
          <img src={four} height='100px' />
        </Flex>
        <Flex alignItems='center' justifyContent='center' flexDirection='column'>
          <Text textAlign='center'>WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND.</Text>
          <Text textAlign='center'>
            PLEASE START FROM THE <a href='/'>HOME PAGE</a>
          </Text>
        </Flex>
      </Flex>
    </DefaultLayout>
  );
});

NotFoundPage.displayName = 'NotFoundPage';

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
